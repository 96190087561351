<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- companyId -->
        <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" label="company" :error="error.companyId" :disabled="isEdit || disabled" />
        <!-- status -->
        <n-field-enum v-model="model.status" field-name="status" enum-name="NewsStatus" :error="error.status" label="status" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- html -->
        <n-field-boolean v-model="model.html" label="html" :error="error.html" :disabled="disabled" />
        <!-- category -->
        <n-field-text v-model="model.category" field-name="category" label="category" :error="error.category" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- newsDate -->
        <n-field-date v-model="model.newsDate" field-name="newsDate" label="newsDate" :error="error.newsDate" :disabled="isEdit || disabled" />
        <!-- newsTime -->
        <n-field-datetime v-model="model.newsTime" :format="DATE_FORMAT.YMD_HM_3" field-name="newsTime" label="newsTime" :error="error.newsTime" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- priority -->
        <n-field-enum v-model="model.priority" field-name="priority" enum-name="NewsPriority" :error="error.priority" label="priority" :disabled="disabled" />
        <!-- language -->
        <n-field-enum v-model="model.language" field-name="language" enum-name="Language" :error="error.language" label="language" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <!-- externalUrl -->
        <n-field-text v-model="model.externalUrl" field-name="externalUrl" label="externalUrl" :error="error.externalUrl" :disabled="disabled" />
      </n-row-col1><n-row-col1>
      <!-- newsTitle -->
      <n-field-text v-model="model.newsTitle" field-name="newsTitle" label="newsTitle" :error="error.newsTitle" :disabled="disabled" />
    </n-row-col1>
      <n-row-col1>
        <!-- newsContent -->
        <n-text-editor v-model="model.newsContent" :html="model.html" field-name="newsContent" label="newsContent" :error="error.newsContent" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, add, edit } from '@/api/company/company-news';
import {DATE_FORMAT} from "@/constant/format";

export default NModal.extend({
  name: 'ModalCompanyNews',
  components: { NModal },

  data() {
    return {
      DATE_FORMAT,
    };
  },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
