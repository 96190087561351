<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="companyId">{{ $t('label.company') }}</th>
            <th class="html">{{ $t('label.html') }}</th>
            <th class="status">{{ $t('label.status') }}</th>
            <th class="language">{{ $t('label.language') }}</th>
            <th class="category">{{ $t('label.category') }}</th>
            <th class="title">{{ $t('label.title') }}</th>
            <th class="n-table-timestamp">{{ $t('label.time') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td><n-icon-boolean :value="row.html" /></td>
            <td v-enums:NewsStatus="row.status"></td>
            <td v-enums:Language="row.language"></td>
            <td class="n-ellipsis" :title="row.category">{{ row.category | hyphen }}</td>
            <td class="n-ellipsis" :title="row.newsTitle">{{ row.newsTitle | hyphen }}</td>
            <td>{{ row.newsTime | datetime }}</td>
            <td class="n-button-box"><nb-modal @on-click="openModal(row)" /></td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import ModalCompanyNews from './ModalCompanyNews';
import { fetch } from '@/api/company/company-news';
import PaginateMixin from "@/mixins/paginate-mixin";

export default BaseCompanyView.extend({
  name: 'CompanyNews',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: 0,
        from: this.oneWeekAgo,
        to: this.eod,
      },
    };
  },

  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },

    parse(data) {
      this.records = data.records;
    },

    openModal(model = {}) {
      this.createModal(ModalCompanyNews, { model, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  th.companyId, th.html, th.status, th.language {
    width: 10rem;
  }

  th.rw-action {
    width: 4rem;
  }

  th.ro-action {
    width: 4.5rem;
  }
}
</style>
